import { graphql } from "gatsby"
import React, { FC } from "react"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import SEO from "src/components/SEO"
import ArrowLink from "src/components/ArrowLink"
import Link from "src/utils/Link"
import { usePages } from "src/context/pages"
import Logo from "src/components/Icons/Logo"
import { useNewsletterSubscription } from "src/hooks/useNewsletterSubscription"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { getPage, currentPath } = usePages()

  const newsletter = useNewsletterSubscription("newsletter", texts)

  return (
    <>
      <SEO
        title={texts.metaTitle}
        description={texts.metaDescription}
        openGraph={{
          title: texts.metaTitle,
          description: texts.metaDescription,
          url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
          images: [
            {
              url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
              width: 800,
              height: 600,
              alt: "Flow logo",
            },
            {
              url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
              width: 900,
              height: 800,
              alt: "Flow logo",
            },
          ],
        }}
      />
      <main className={styles.main}>
        <Link to={getPage("index").url}>
          <Logo className={styles.logo} />
        </Link>
        <div
          className={`${styles.container} ${
            newsletter.status === "success" ? styles.containerSuccess : ""
          }`}
        >
          {newsletter.status === "success" ? (
            <>
              <h1 className={styles.title}>{texts.verifyTitle}</h1>
              <p className={styles.description}>{texts.verifySubtitle}</p>
              <Link
                to={getPage("blog").url}
                className={`button button-second ${styles.button}`}
              >
                {texts.button}
              </Link>
              <ArrowLink
                text={texts.link}
                to={getPage("index").url}
                className={styles.link}
              />
            </>
          ) : (
            <>
              <h1 className={styles.title}>{texts.header}</h1>
              <p className={styles.description}>{texts.description}</p>

              <div
                className={`${styles.separatorText} ${
                  styles.separatorText_Margin
                } ${newsletter.status === "error" && styles.error}`}
              >
                {newsletter.status === "error"
                  ? newsletter.error
                  : texts.orEmail}
              </div>

              <form
                className={`${styles.contentInput} ${newsletter.status ===
                  "error" && styles.error}`}
                {...newsletter.formProps}
              >
                <input
                  className={styles.emailInput}
                  type="text"
                  placeholder={texts.placeholderEmail}
                  {...newsletter.inputProps}
                />
                <button
                  className={`button button-second ${styles.emailButton}`}
                  type="submit"
                  {...newsletter.buttonProps}
                >
                  {texts.signUp}
                </button>
              </form>
              <span className={styles.infoText}>{texts.infoText}</span>
            </>
          )}
        </div>

        <div className={styles.legalText}>
          Flow © 2020 <span className={styles.dot}> • </span>
          <Link
            to={getPage("legal/terms-of-service").url}
            className={styles.linkLegal}
          >
            {texts.Terms}
          </Link>
          <span> {texts.and} </span>
          <Link
            to={getPage("legal/privacy-policy").url}
            className={styles.linkLegal}
          >
            {texts.Privacy}
          </Link>
        </div>
      </main>
    </>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        description
        placeholderEmail
        signUp
        infoText
        verifyTitle
        verifySubtitle
        button
        link
        Terms
        and
        Privacy
        invalidEmailError
        unknownSubscriptionError
      }
    }
  }
`

export default Template
